/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap");
@font-face {
  font-family: "Ailerons_A";
  src: local("Ailerons_A"),
    url(./assets/fonts/Ailerons_A.ttf) format("truetype");
}

@font-face {
  font-family: "albr55w_E";
  src: local("albr55w_E"), url(./assets/fonts/albr55w_E.TTF) format("truetype");
}

@font-face {
  font-family: "calibri";
  src: local("calibri"), url(./assets/fonts/calibri.ttf) format("truetype");
}

@font-face {
  font-family: "calibrib";
  src: local("calibrib"), url(./assets/fonts/calibrib.ttf) format("truetype");
}

@font-face {
  font-family: "calibril";
  src: local("calibril"), url(./assets/fonts/calibril.ttf) format("truetype");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ailerons_A", sans-serif !important;
}

/* p {
  font-family: 'calibril', sans-serif !important;
} */

:root {
  /* Between Production colours */
  --btGray: #e9e4de;
  --btBlack: #10171f;
  --btPaperWhite: #f1edea;
  --btLogoYellow: #ebae45;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
